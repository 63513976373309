import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CColorCard,
  CLabelCard,
  LRecruitUniqueSect,
  CVideoMedia,
  CVideo,
} from "../../components/_index";
import { size } from "lodash";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            ja: "採用情報",
          },
          img: {
            src: "/assets/images/recruit/kv.png",
          },
          imgSp: {
            src: "/assets/images/recruit/kv__sp.png",
          },
        }}
      />
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: "採用情報",
          },
        }}
      />
      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle
            title={{
              ja: "募集要項",
              en: "APPLICATION GUIDELINES",
            }}
          />
          <p className="c_sectLead">
            ロイヤルパークホテルズは、その街ならではのおもてなし、お客様一人ひとりに合った居心地の良さを追求し、
            <br className="u_pc" />
            日本のおもてなしを象徴する「よい加減」のサービスを大切にしています。
            <br />
            当社の一員となって共に成長していきませんか。
          </p>
          <CColorCard
            data={[
              {
                title: <>新卒採用</>,
                content: <>詳細は下記の「マイナビ2026」よりご覧ください。</>,
                btn: {
                  img: {
                    img: {
                      src: "/assets/images/recruit/banner_logo.svg",
                      alt: "マイナビ2026",
                    },
                    link: {
                      href: "https://job.mynavi.jp/26/pc/search/corp98069/outline.html",
                      blank: true,
                    },
                    icon: "blank",
                  },
                },
              },
              {
                title: <>キャリア採用</>,
                content: (
                  <>
                    <p className="u_mb20">
                      これまでの経験とスキルを活かしさらにスキルアップを目指したい方、
                      <br />
                      新しい分野へステップアップしたい方など、ぜひご応募ください。
                    </p>
                  </>
                ),
                btn: {
                  normal: {
                    label: "募集要項",
                    link: {
                      href: "https://hrmos.co/pages/mjhr/jobs/000001HR",
                      blank: true,
                    },
                    icon: "blank",
                  },
                },
              },
              {
                title: <>パート・アルバイト採用</>,
                content: (
                  <>
                    <p className="u_mb20">
                      親しみやすく活気溢れるスタッフと共に、
                      <br />
                      楽しく働きながら活躍することができます。アルバイトを通じて、ホテルのサービスやおもてなしについて学びませんか。
                    </p>
                  </>
                ),
                btn: {
                  normal: {
                    label: "募集要項",
                    link: {
                      href: "https://hrmos.co/pages/mjhr/jobs/000002HP",
                      blank: true,
                    },
                    icon: "blank",
                  },
                },
              },
              {
                title: <>リファラル採用</>,
                content: (
                  <>
                    <p className="u_mb20">
                      弊社社員のご友人や知人の方で、紹介を受けられた方はご応募ください。
                      <br />
                      社風、職場風土、仕事の進め方などを事前に紹介者に確認いただけますので、入社後のギャップも少なく、円滑に業務に取り組んでいただけます。
                    </p>
                  </>
                ),
                btn: {
                  normal: {
                    label: "募集要項",
                    link: {
                      href: "https://hrmos.co/pages/mjhr/jobs/000003HP",
                      blank: true,
                    },
                    icon: "blank",
                  },
                },
              },
              {
                title: <>アルムナイ採用</>,
                content: (
                  <>
                    <p className="u_mb20">
                      弊社を一度、卒業された方に向け、再度活躍いただける場をご提供していきたいと考えております。
                      <br />
                      もう一度、弊社で挑戦したい。そんな志をお持ちの皆様からのご応募をお待ちしております。
                    </p>
                  </>
                ),
                btn: {
                  normal: {
                    label: "募集要項",
                    link: {
                      href: "https://hrmos.co/pages/mjhr/jobs/000004HP",
                      blank: true,
                    },
                    icon: "blank",
                  },
                },
              },
            ]}
          />

        </LWrap>
      </section>
      <LRecruitUniqueSect>
        <CSectTitle
          color="white"
          exClass="u_mb120_sp"
          title={{
            ja: "ロイヤルパークホテルズについて",
            en: "ABOUT ROYAL PARK HOTELS",
          }}
        />
        <CVideo
          video={{
            src: "/assets/movie/kv.mp4",
          }}
        />
      </LRecruitUniqueSect>
      <section className="l_sect u_bgGray" id="interview">
        <LWrap>
          <CSectTitle
            title={{
              ja: "社員の声",
              en: "STAFF INTERVIEW",
            }}
          />
          <CLabelCard
            data={[
              {
                img: {
                  src: "/assets/images/recruit/img_staff.png",
                  alt: "",
                },
                link: {
                  href: "/recruit/article01/",
                },
                labels: ["仙台ロイヤルパークホテル"],
                title: {
                  dataMain: "柴 朋也",
                  sub: <>料飲</>,
                },
              },
              {
                img: {
                  src: "/assets/images/recruit/img_staff02.png",
                  alt: "",
                },
                link: {
                  href: "/recruit/article03/",
                },
                labels: ["ザ ロイヤルパーク キャンバス京都二条"],
                title: {
                  dataMain: "堤 香奈子",
                  sub: <>フロントチーム</>,
                },
              },
              {
                img: {
                  src: "/assets/images/recruit/img_staff03.png",
                  alt: "",
                },
                link: {
                  href: "/recruit/article02/",
                },
                labels: ["大阪・神戸エリア"],
                title: {
                  dataMain: "橋本 由衣",
                  sub: <>管理課</>,
                },
              },
            ]}
          />
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
